<svg
  xmlns="http://www.w3.org/2000/svg"
  width="13"
  height="13"
  viewBox="0 0 13 13"
>
  <circle cx="4.789" cy="4.787" r="3.85" style="stroke-width:1.875;fill:none" />
  <path
    d="M12.063 12.063 7.635 7.635"
    style="stroke-width:1.875;stroke-linecap:round"
  />
</svg>

<style>
  svg {
    display: block;
    stroke: var(--color-icon-button);
  }
</style>
